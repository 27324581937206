import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class MobileMenu {
  private $toggleButton: HTMLButtonElement;
  private $closeButton: HTMLButtonElement;
  private $drawer: HTMLElement;

  private isOpen: boolean = false;

  constructor() {
    this.$toggleButton = document.querySelector('.header__hamburger-menu');
    this.$closeButton = document.querySelector('.mobile-nav__close');
    this.$drawer = document.querySelector('.mobile-nav');

    this.$toggleButton.addEventListener('click', (event) => this.onToggleButtonClicked(event));
    this.$closeButton.addEventListener('click', (event) => this.onCloseButtonClicked(event));

    document.body.addEventListener('click', (event) => this.onBodyClicked(event));

    window.addEventListener('resize', () => this.onWindowResized());
  }

  private onToggleButtonClicked(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (!this.isOpen) {
      this.openDrawer();
    } else {
      this.closeDrawer();
    }
  }

  private onCloseButtonClicked(event: MouseEvent): void {
    event.preventDefault();

    this.closeDrawer();
  }

  private onBodyClicked(event: MouseEvent): void {
    if (!this.isOpen) {
      return;
    }

    let $target = event.target as HTMLElement;

    while ($target.tagName.toLowerCase() !== 'body') {
      if ($target.classList.contains('mobile-nav')) {
        return;
      }

      $target = $target.parentElement;
    }

    this.closeDrawer();
  }

  private onWindowResized(): void {
    if (this.isOpen && window.innerWidth > 1410) {
      this.closeDrawer();
    }
  }

  private openDrawer(): void {
    disableBodyScroll(this.$drawer);

    this.$drawer.style.right = '0';

    this.isOpen = true;
  }

  public closeDrawer(): void {
    enableBodyScroll(this.$drawer);

    this.$drawer.style.right = '-100%';

    this.isOpen = false;
  }
}
