import smoothscroll from 'smoothscroll-polyfill';
import WebFont from 'webfontloader';

import MobileMenu from './components/MobileMenu';
import Nav from './components/Nav';

smoothscroll.polyfill();

if (!Object.getOwnPropertyDescriptor(NodeList.prototype, 'forEach')) {
  Object.defineProperty(NodeList.prototype, 'forEach', Object.getOwnPropertyDescriptor(Array.prototype, 'forEach'));
}

WebFont.load({
  google: {
    families: [
      'Montserrat',
    ],
  },
  active: () => {
    const mobileMenu = new MobileMenu();
    new Nav(mobileMenu);
  },
});
