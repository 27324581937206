import MobileMenu from './MobileMenu';

export default class Nav {
  public static readonly DISTANCE_FROM_SECTION = 64;

  private $mobileLinks: NodeListOf<HTMLAnchorElement>;
  private $links: NodeListOf<HTMLAnchorElement>;

  private mobileMenu: MobileMenu;

  constructor(mobileMenu: MobileMenu) {
    this.$mobileLinks = document.querySelectorAll('.mobile-nav__link');
    this.$links = document.querySelectorAll('.header__nav__link');

    this.mobileMenu = mobileMenu;

    this.$mobileLinks.forEach(($mobileLink) => {
      $mobileLink.addEventListener('click', (event) => this.onMobileLinkClicked(event));
    });

    this.$links.forEach(($link) => {
      $link.addEventListener('click', (event) => this.onLinkClicked(event));
    });
  }

  private onLinkClicked(event: MouseEvent): void {
    event.preventDefault();

    const $target = event.target as HTMLAnchorElement;

    this.scrollTo($target.getAttribute('href').substr(1));
  }

  private onMobileLinkClicked(event: MouseEvent): void {
    event.preventDefault();

    this.mobileMenu.closeDrawer();

    const $target = event.target as HTMLAnchorElement;

    this.scrollTo($target.getAttribute('href').substr(1));
  }

  private scrollTo(id: string): void {
    const $section = document.querySelector(`.section#${id}`) as HTMLElement;

    if (!$section) {
      return;
    }

    window.scroll({
      behavior: 'smooth',
      top: $section.offsetTop - Nav.DISTANCE_FROM_SECTION,
    });
  }
}
